.container {
    align-items: center;
  
    div {
      gap: var(--gap);
      align-items: center;
      background-color: rgba(255,255,255,0.3);
      border-radius: 40px;
      padding: 40px 80px;
      box-shadow: var(--shadow);
    }
  
    h1 {
      font-size: 3rem;
    }
    h2 {
      font-size: 2rem;
      font-weight: 400;
    }
    span {
      font-size: 10rem;
      line-height: 93%;
    }
    nav {
      flex-direction: row;
      gap: var(--gap);
      margin-top: 10px;
  
      a {
        padding: 10px 30px;
        border-radius: 5px;
        font-size: 1.4rem;
        background-color: var(--color-tint);
        color: var(--color-sub);
        font-weight: 500;
        letter-spacing: -0.5px;
        transform: scale(1, 1.05);
        
        &:hover {
          background-color: var(--color-sub);
          color: var(--color-tint);
          box-shadow: var(--shadow);
        }
      }
    }
  }